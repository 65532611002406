/* eslint-disable no-console */
/* eslint-disable react/no-unused-state */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import axios from 'axios';
import Articles from 'parts/Articles';
import Header from 'parts/Header';
import HeroBlog from 'parts/HeroBlog';
import Footer from 'parts/Footer';

export default class NotFoundPage extends React.Component {
  // eslint-disable-next-line react/state-in-constructor
  state = {
    posts: [],
    page: 1,
    isLoading: false,
    errorMsg: '',
    displayNone: '',
  };

  componentDidMount() {
    this.loadPosts();
  }

  componentDidUpdate(prevProps, prevState) {
    const { page } = this.state;
    if (prevState.page !== page) {
      this.loadPosts();
    }
  }

  loadPosts = async () => {
    try {
      const { page } = this.state;

      this.setState({ isLoading: true });
      const response = await axios.get(
        `https://api.haluanmedia.com/wp-json/wp/v2/posts/?per_page=6&page=${page}`,
      );

      this.setState((prevState) => ({
        posts: [...prevState.posts, ...response.data],
        errorMsg: '',
        displayNone: '',
      }));
    } catch (error) {
      this.setState({
        errorMsg: 'Finished loading data. Try again later.',
        displayNone: 'invisible',
      });
    } finally {
      this.setState({ isLoading: false });
    }
  };

  loadMore = () => {
    this.setState((prevState) => ({
      page: prevState.page + 1,
    }));
  };

  render() {
    const { posts, isLoading, errorMsg } = this.state;
    const { displayNone } = this.state;

    return (
      <>
        <Header {...this.props} />
        <HeroBlog {...this.props} />
        <Articles data={posts} />
        {errorMsg && <div className="p-4 text-sm text-gray-700 rounded-lg dark:bg-gray-700 dark:text-gray-300 text-center" role="alert"><span className="font-medium">{errorMsg}</span></div>}
        <div className="flex justify-center bg-gradient-to-t from-white my-12 dark:from-gray-900 ">
          <div className={displayNone}>
            <button
              type="button"
              className="font-normal px-5 py-2 mr-3 text-theme-blue text-lg border border-theme-blue rounded-full transition duration-300 hover:bg-theme-blue hover:text-white focus:outline-none focus:bg-theme-blue focus:text-white"
              onClick={this.loadMore}
            >
              {isLoading ? 'Loading...' : 'Load More'}
            </button>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}
