/* eslint-disable no-console */
/* eslint-disable react/no-unused-state */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-filename-extension */

import React, { Component } from "react";
import axios from "axios";
import Header from "parts/Header";
import Fade from "react-reveal/Fade";
import Button from "elements/Button";
// import { Seo } from "parts/Seo";
import Footer from "parts/Footer";
import Moment from "moment";
import { Helmet } from 'react-helmet';

export default class ArticleDetailPost extends Component {
  // eslint-disable-next-line react/state-in-constructor
  state = {
    posts: [],
    isLoaded: false,
  };

  componentDidMount() {
    axios
      .get(
        `https://api.haluanmedia.com/wp-json/wp/v2/posts/${this.props.match.params.id}`,
      )
      .then((res) => this.setState({
        posts: res.data,
        title: res.data.title.rendered,
        content: res.data.content.rendered,
        isLoaded: true,
      }))
      .catch((err) => console.log(err));
    window.scrollTo(0, 0);
  }

  render() {
    const { posts, title, content } = this.state;
    const formatDate = Moment(posts.date).format("DD MMM YYYY");
    return (
      <>
        <Helmet>
          <title>{title ? `${title} - Haluan Media Group` : 'Haluan Media Group - Newspaper and Digital Media Creative'}</title>
        </Helmet>
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <Header {...this.props} />
        <section className="container mx-auto">
          <Fade>
            <Button type="link" href="/newsroom" className="flex w-40 h-8 text-lg items-center ml-6 sm:ml-20 mt-8 font-light text-gray-400 hover:underline">
              <svg className="w-5 h-5 text-gray-400 mr-1" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 19l-7-7 7-7" />
              </svg>
              Back
            </Button>
          </Fade>
          <div className="flex flex-col mt-8 lg:mx-auto max-w-4xl justify-center">
            <Fade>
              <h1 className="text-2xl sm:text-5xl text-theme-blue text-center font-bold mx-3">
                {title}
              </h1>
              <p className="font-light text-sm sm:text-xl text-gray-400 text-center mb-10">
                {formatDate}
              </p>
            </Fade>
            <Fade>
              <div className="flex justify-center xl:mb-6">
                <img src={posts.jetpack_featured_media_url} alt={title} className="flex w-4/5 sm:w-4/6" />
              </div>
            </Fade>
            <Fade>
              <div className="flex flex-col mt-8 mb-12 mx-8 sm:mx-16 xl:mx-28">
                <div className="read-content font-light text-lg text-gray-900 text-left">
                  {/* eslint-disable-next-line */}
                  <div dangerouslySetInnerHTML={{ __html: content }} />
                </div>
              </div>
            </Fade>
          </div>
        </section>
        <Footer />
      </>
    );
  }
}
