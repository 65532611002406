/* eslint-disable max-len */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/prop-types */
/* eslint-disable */

import React from 'react';
import parse from 'html-react-parser'
import Fade from 'react-reveal/Fade';
import Button from 'elements/Button';

export default function ContactPageContent() {
  return (
    <section className="container mx-auto">
      <Fade bottom>
        <Button type="link" href="/" className="flex w-40 h-8 text-lg items-center ml-6 sm:ml-20 mt-8 font-light text-gray-400 hover:underline">
          <svg className="w-5 h-5 text-gray-400 mr-1" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 19l-7-7 7-7" />
          </svg>
          Back
        </Button>
      </Fade>
                  <div className="flex flex-col mt-8 lg:mx-32 xl:mx-36 2xl:mx-40 justify-center">
                    <Fade bottom>
                      <h1 className="text-3xl sm:text-5xl text-theme-blue text-center font-bold">Contact Us</h1>
                    </Fade>

                    <Fade bottom delay={300 * 1}>
                      <div className="flex flex-col mt-16 mb-12 mx-8 sm:mx-16 xl:mx-28">
                        <h1 className="text-3xl text-theme-blue font-bold mb-3">
                        Egi Muliandri
                        </h1>
                        <p className="font-light text-lg text-gray-400 text-justify">
                        <strong>Director of Business Development </strong><br />
                        Telepon : +62 81231610020 <br />
                        Email : egi@haluanmedia.com
                        </p>
                      </div>
                      <div className="flex flex-col mt-2 mb-12 mx-8 sm:mx-16 xl:mx-28">
                        <h1 className="text-3xl text-theme-blue font-bold mb-3">
                        Rayhan
                        </h1>
                        <p className="font-light text-lg text-gray-400 text-justify">
                        <strong>General Manager Haluan Creative </strong><br />
                        Telepon : +62 81263621766 <br />
                        Email : rayhan@haluanmedia.com
                        </p>
                      </div>
                      <div className="flex flex-col mt-2 mb-12 mx-8 sm:mx-16 xl:mx-28">
                        <h1 className="text-3xl text-theme-blue font-bold mb-3">
                        Haluan Media Network
                        </h1>
                        <p className="font-light text-lg text-gray-400 text-justify">
                        <strong>Jakarta </strong><br />
                        Jl. Kebun Kacang XXIV No. 05/04, RT.13/RW.4, Kb. Kacang, Kecamatan Tanah Abang, Kota Jakarta Pusat, Daerah Khusus Ibukota Jakarta 10240
                        </p><br />
                        <p className="font-light text-lg text-gray-400 text-justify">
                        <strong>Sumatera Barat </strong><br />
                        Jl. Prof. Dr. Hamka No.2A, Air Tawar Tim., Kec. Padang Utara, Kota Padang, Sumatera Barat 25173
                        </p><br />
                        <p className="font-light text-lg text-gray-400 text-justify">
                        <strong>Kepulauan Riau </strong><br />
                        Jl. Yos Sudarso No. 9 Batu Ampar, Bengkong Laut, Kec. Bengkong, Kota Batam, Kepulauan Riau 29432
                        </p>
                      </div>
                    </Fade>
                  </div>
    </section>
  );
}
