/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/jsx-props-no-spreading */
import React, { Component } from 'react';
import Header from 'parts/Header';
import Button from 'elements/Button';
import Footer from 'parts/Footer';

// eslint-disable-next-line react/prefer-stateless-function
export default class NotFoundPage extends Component {
  render() {
    return (
      <>
        <Header {...this.props} />
        <div className="flex flex-col w-full h-screen justify-center">
          <div className="flex w-full justify-center">
            {/* <img src={NotFound} alt="Not Found" className="sm:w-3/4 xl:w-5/12" /> */}
          </div>
          <h1 className="text-black text-2xl text-center mt-5">
            {/* eslint-disable-next-line react/no-unescaped-entities */}
            404 | PAGE NOT FOUND...
          </h1>
          <div className="flex justify-center">
            <Button href="/" type="link" className="flex w-30 h-10 px-5 mt-5 bg-gray-600 text-white items-center rounded transform transition duration-500 hover:bg-gray-900">
              Go Back
            </Button>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}
