/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/jsx-filename-extension */

import React, { Component } from 'react';

import Header from 'parts/Header';
import HeroProduct from 'parts/HeroProduct';
import Footer from 'parts/Footer';
import Allproduct from 'parts/Allproduct';

import Data from 'json/landingPage.json';

export default class ProductPage extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <>
        <Header {...this.props} />
        <HeroProduct {...this.props} />
        <Allproduct data={Data.product} />
        <Footer />
      </>
    );
  }
}
