/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/react-in-jsx-scope */
// eslint-disable-next-line no-unused-vars
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import LandingPage from 'pages/LandingPage';
import ProductPage from 'pages/ProductPage';
import BlogPage from 'pages/BlogPage';
import ProductDetailPage from 'pages/ProductDetailPage';
import ArticleDetailPost from 'pages/ArticleDetailPost';
import TeamPage from 'pages/TeamPage';
import NotFoundPage from 'pages/NotFoundPage';

import 'assets/css/styles.css';
import ContactPage from 'pages/ContactPage';

function App() {
  return (
    <Switch>
      <Route exact path="/" component={LandingPage} />
      <Route exact path="/product" component={ProductPage} />
      <Route exact path="/product/:id" component={ProductDetailPage} />
      <Route exact path="/leader" component={TeamPage} />
      <Route exact path="/newsroom" component={BlogPage} />
      <Route exact path="/contact" component={ContactPage} />
      <Route exact path="/article/:id/:slug" component={ArticleDetailPost} />
      <Route path="" component={NotFoundPage} />
    </Switch>
  );
}

export default App;
