/* eslint-disable import/no-unresolved */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-filename-extension */

import React, { Component } from 'react';

import Header from 'parts/Header';
import ContactPageContent from 'parts/ContactPageContent';
import Footer from 'parts/Footer';

export default class ContactPage extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <>
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <Header {...this.props} />
        <ContactPageContent />
        <Footer />
      </>
    );
  }
}
